import { useContext } from "react";
import Head from "next/head";

import Layout from "../components/Layout";

import { pageStyle, xLargeFont } from "../styles";
import { Context } from "../context";
import { getTop } from "../api";
import SubNav from "../components/SubNav";
import ItemsHorizontalOverlay from "../components/ItemsHorizontalOverlay";
import ItemsGridSide from "../components/ItemsGridSide";
import ItemsGrid from "../components/ItemsGrid";

const Home = ({ top }) => {
  const { theme } = useContext(Context);

  return (
    <Layout>
      <Head>
        <title>Video Game Sources, Analysis, Reviews, and Walthroughs - GameQik</title>
      </Head>
      <div>
        <style jsx>{pageStyle}</style>
        <style jsx>{`
          .subtitle {
            font-size: ${xLargeFont};
            margin-bottom: 2rem;
          }
          .top-reviews {
            background: ${theme.selectedBackgroundColor};
            padding: 2rem 0;
          }
          .latest-news {
            margin-top: 2rem;
          }
        `}</style>
        <SubNav
          links={top.reviews.slice(5).map((review) => ({
            name: review.game.name,
            url: `/review/${review.slug}`,
          }))}
        />
        <div className="section">
          <ItemsHorizontalOverlay items={top.articles.slice(0, 3)} />
        </div>
        <div className="top-reviews">
          <div className="section">
            <h2 className="subtitle">Top Reviews</h2>
            <ItemsGrid items={top.reviews.slice(0, 6)} />
          </div>
        </div>
        <div className="latest-news">
          <div className="section">
            <h2 className="subtitle">Latest News</h2>
            <ItemsGridSide items={top.articles.slice(3, 9)} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

Home.getInitialProps = async ({ store, cached }) => {
  const { top } = await getTop({ cached });
  const analytics = {
    content_group1: "Home",
  };
  return { top, analytics };
};

export default Home;
