import { useContext } from "react";
import { Context } from "../context";
import { breakpoint1 } from "../styles";
import Image from "../components/Image";

const ItemsGrid = ({ items }) => {
  const { theme } = useContext(Context);
  return (
    <div className="items">
      <style jsx>
        {`
          .items {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1rem;
          }
          .item {
            text-decoration: none;
          }
          .title {
            color: ${theme.primaryTextColor};
            font-size: 1.3rem;
            margin: 1rem 0;
          }
          .item:hover .title {
            text-decoration: underline;
            text-underline-position: under;
          }
          @media screen and (min-width: ${breakpoint1}px) {
            .items {
              grid-template-columns: 1fr 1fr 1fr;
            }
          }
        `}
      </style>
      {items.map((item) => (
        <a className="item" key={item.slug} href={`/${item.type}/${item.slug}`}>
          <Image path={item.image} width={350} height={200} />
          <h3 className="title">{item.title}</h3>
        </a>
      ))}
    </div>
  );
};

export default ItemsGrid;
