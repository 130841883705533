import { cloudflareImage } from "../utils";

const Image = ({ path, width, height, fit = "cover", scale = 1.5 }) => (
  <div>
    <style jsx>
      {`
        div {
          padding-top: ${(height / width) * 100}%;
          position: relative;
          overflow: hidden;
        }
        img {
          position: absolute;
          top: 0;
          left: 50%;
          width: 100%;
          transform: translateX(-50%);
          right: 0;
          bottom: 0;
          transition: transform 0.25s ease-in-out;
        }
        img:hover {
          transform: translateX(-50%) scale(${scale});
        }
      `}
    </style>
    <img
      data-src={cloudflareImage(path, {
        width: 2 * width * scale,
        height: 2 * height * scale,
        fit,
      })}
      src="/static/images/placeholder.png"
    />
  </div>
);
export default Image;
