import { smallFont, mediumFont, breakpoint1 } from "../styles";
import { cloudflareImage } from "../utils";

const OverlayImage = ({
  overlay,
  path,
  width,
  height,
  fit = "cover",
  scale = 1.3,
  transition = 0.3,
}) => {
  return (
    <div>
      <style jsx>{`
        div {
          position: relative;
          padding-top: ${(height / width) * 100}%;
          overflow: hidden;
        }
        img {
          position: absolute;
          top: 0;
          left: 50%;
          width: 100%;
          transform: translateX(-50%);
          transition: transform ${transition}s ease-in-out;
        }
        div:hover img {
          transform: translateX(-50%) scale(${scale});
        }
        h3 {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          font-size: ${smallFont};
          text-align: center;
          padding: 1rem;
          line-height: 150%;
          background: rgba(0, 0, 0, 0.5);
          color: #ddd;
        }
        div:hover h3 {
          color: #fff;
          text-decoration: underline;
          text-underline-position: under;
        }
        @media screen and (min-width: ${breakpoint1}px) {
          h3 {
            font-size: ${mediumFont};
          }
        }
      `}</style>
      <img
        data-src={cloudflareImage(path, {
          width: parseInt(2 * width * scale),
          height: parseInt(2 * height * scale),
          fit,
        })}
        src="/static/images/placeholder.png"
      />
      <h3>{overlay}</h3>
    </div>
  );
};

export default OverlayImage;
