import { useContext } from "react";

import { Context } from "../context";
import { maxSection1, maxSection2, breakpoint1, padding0, padding1 } from "../styles";

const SubNav = ({ links }) => {
  const { theme } = useContext(Context);
  return (
    <div className="subnav">
      <style jsx>{`
        .subnav {
          border-bottom: 1px solid ${theme.borderColor};
          display: none;
          justify-content: center;
        }
        .subnav-links {
          width: ${maxSection1}px;
          max-width: 100%;
          padding: 0 ${padding0}px;
          display: flex;
        }
        .link {
          padding: 1rem;
          text-underline-position: under;
          text-decoration: none;
          color: ${theme.primaryTextColor};
          flex-grow: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .link:first-child {
          padding-left: 0;
        }
        .link:last-child {
          padding-right: 0;
        }
        @media screen and (min-width: ${breakpoint1}px) {
          .subnav {
            display: flex;
          }
          .subnav-links {
            width: ${maxSection2}px;
            padding: 0 ${padding1}px;
          }
        }
      `}</style>
      <div className="subnav-links">
        {links.map(({ name, url }) => (
          <a className="link" key={url} href={url}>
            {name}
          </a>
        ))}
      </div>
    </div>
  );
};

export default SubNav;
