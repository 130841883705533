import { useContext } from "react";

import { resetStyle } from "../styles";
import { Context } from "../context";
import Navbar from "./Navbar";
import Footer from "./Footer";
import CommonHead from "./CommonHead";
import TrafficCop from "./TrafficCop";

const Layout = ({ children, noAds }) => {
  const { theme } = useContext(Context);

  return (
    <>
      <style jsx global>
        {resetStyle}
      </style>
      <style jsx global>{`
        body {
          color: ${theme.primaryTextColor};
          background-color: ${theme.backgroundColor};
        }
      `}</style>
      <CommonHead />
      <Navbar />
      <div className="page">{children}</div>
      <Footer />
      <TrafficCop />
    </>
  );
};

export default Layout;
