import { useContext } from "react";
import { Context } from "../context";
import OverlayImage from "./OverlayImage";

const ItemsHorizontalOverlay = ({ items }) => {
  const { theme } = useContext(Context);
  return (
    <div className="container">
      <style jsx>{`
        .items {
          overflow: scroll;
          margin: 2rem 0;
          display: grid;
          grid-auto-flow: column;
          grid-auto-columns: minmax(200px, auto);
          grid-gap: 1rem;
        }
      `}</style>
      <div className="items">
        {items.map((item) => (
          <a className="item" key={item.slug} href={`/${item.type}/${item.slug}`}>
            <OverlayImage path={item.image} width={350} height={550} overlay={item.title} />
          </a>
        ))}
      </div>
    </div>
  );
};

export default ItemsHorizontalOverlay;
